import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
})
export class FileViewComponent {
  @Input() isImage: boolean;
  @Input() fileName: string;

  constructor(private sanitizer: DomSanitizer) {}

  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fileName);
  }
}
