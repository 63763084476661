import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  myStorage = window.localStorage;

  setIdentity(identity: any) {
    if (identity) {
      this.myStorage.setItem('identity', JSON.stringify(identity));
    }
  }

  removeIdentity() {
    this.myStorage.removeItem('identity');
  }

  getIdentity(): any {
    const identity: string = this.myStorage.getItem('identity');
    return identity ? JSON.parse(identity) : null;
  }

  getUserId(): any {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const userId = JSON.parse(identity).userId;
      if (userId) {
        return userId;
      }
    }
    return null;
  }

  getToken(): any {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const token = JSON.parse(identity).accessToken;
      if (token) {
        return token;
      }
    }
    return null;
  }

  isAuthenticated(): boolean {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      return true;
    }
    return false;
  }

  getUserName() {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const userName = JSON.parse(identity).userName;
      if (userName) {
        return userName;
      }
    }
    return null;
  }

  getEnvironment() {
    const identity: string = this.myStorage.getItem('identity');
    if (identity) {
      const env = JSON.parse(identity).env;
      if (env) {
        return env;
      }
    }
    return null;
  }

  setChangePasswordToken() {
    sessionStorage.setItem('changePasswordUi', this.getUserId());
  }

  isActiveChangePasswordUi(): boolean {
    const item = sessionStorage.getItem('changePasswordUi');
    if (item) {
      return item === this.getUserId();
    } else return false;
  }

  removeChangePasswordToken() {
    sessionStorage.removeItem('changePasswordUi');
  }
}
