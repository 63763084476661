import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { Observable, Observer } from 'rxjs';
import { FileService } from '../../service/file.service';

import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import {
  HttpRequest,
  HttpClient,
  HttpEventType,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormGroup } from '@angular/forms';
import { FileViewComponent } from '../modules/file-view/file-view.component';

function getBase64(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

@Component({
  selector: 'app-image-slip-upload',
  templateUrl: './image-slip-upload.component.html',
})
export class ImageSlipUploadComponent implements AfterViewInit {
  invoicePaymentForm: FormGroup;
  displayStyle = 'inline';
  secondType: any;
  isSpinning = false;
  @Input() payment: any;
  @Input() data: any;
  fileName: any;

  nzAction: any;
  files: any;
  icons = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    showDownloadIcon: true,
  };
  previewImage: string | undefined = '';
  previewVisible = false;
  constructor(
    private el: ElementRef,
    private messageService: NzMessageService,
    private fileService: FileService,
    private http: HttpClient,
    private modelRef: NzModalRef,
    private modalService: NzModalService
  ) {}

  handleRemove = (file: any, _fileList: any[]) => {
    if (file.id) {
      this.files = this.files.filter((fileTemp) => fileTemp.id !== file.id);
      this.fileService
        .deleteOrderImage(file, this.secondType)
        .subscribe((response: any) => {
          console.log('Order image is deleted successfully');
        });
    } else {
      this.files = this.files.filter((fileTemp) => fileTemp.name !== file.name);
    }
  };

  stateChange(event: any) {
    if (event.file.status === 'error') {
      console.log('Error..........');
    }
  }

  beforeUpload = (file: any, _fileList: any[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isJpgOrPngOrPdf =
        file.type === 'application/pdf' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/bmp';
      if (!isJpgOrPngOrPdf) {
        this.messageService.error('You can only upload image file!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPngOrPdf);
      observer.complete();
    });
  };

  ngAfterViewInit() {
    const upload = this.el.nativeElement.querySelector('nz-upload');
    const container = this.el.nativeElement.querySelector('#filter-container');
    upload.children[0].style.padding = '0 8px';
    container.append(upload.children[0]);
  }

  setMediaUploadHeaders = (file: NzUploadFile) => {
    return {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };
  };

  findImage() {
    this.isSpinning = true;
    this.files = [];
    if (this.secondType) {
      if (this.secondType === 'PO') {
        this.fileName = this.payment.po;
      } else if (this.secondType === 'PI') {
        this.fileName = this.payment.pi;
      } else if (this.secondType === 'paymentSlip') {
        this.fileName = this.payment.paymentSlip;
      } else if (this.secondType === 'taxInvoice') {
        this.fileName = this.payment.invoiceSlip;
      } else if (this.secondType === 'finalPaymentSlip') {
        this.fileName = this.payment.finalPaymentSlip;
      } else if (this.secondType === 'other') {
        this.fileName = this.payment.other;
      }
    }

    this.files.push({
      id: this.payment.id,
      name: this.fileName,
      status: 'done',
      thumbUrl: `http://res-bbmedia-in.s3-website.ap-south-1.amazonaws.com/${this.fileName}`,
      url: `https://res-bbmedia-in.s3.ap-south-1.amazonaws.com/${this.fileName}`,
    });
    this.isSpinning = false;
  }

  showUploadIcon() {
    if (this.secondType) {
      this.findImage();
      this.nzAction = `${environment.API_URL}/file/upload/vendorinvoicepayment/${this.payment.id}/`;
    }
  }

  customUploadReq = (item: NzUploadXHRArgs) => {
    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any
    formData.append('secondType', this.secondType);
    const req = new HttpRequest('POST', item.action, formData, {
      reportProgress: true,
      withCredentials: false,
    });

    return this.http.request(req).subscribe(
      (event: HttpEvent<{}>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total > 0) {
            (event as any).percent = (event.loaded / event.total) * 100; // tslint:disable-next-line:no-any
          }
          // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
          item.onProgress(event, item.file);
        } else if (event instanceof HttpResponse) {
          /* success */
          item.onSuccess(event.body, item.file, event);
        }
      },
      (err) => {
        /* error */
        item.onError(err, item.file);
      }
    );
  };

  handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      // tslint:disable-next-line: no-non-null-assertion
      file.preview = await getBase64(file.originFileObj!);
    }
    const fileName: string = file.url || file.preview;
    const extensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
    const fileExtension = fileName.substring(
      fileName.lastIndexOf('.'),
      fileName.length
    );
    const isImage = extensions.some((f) => f === fileExtension);
    this.modalService.create({
      nzTitle: '',
      nzContent: FileViewComponent,
      nzComponentParams: {
        isImage,
        fileName,
      } as any,
      nzFooter: null,
      nzClosable: false,
      nzWidth: '70vw',
      nzBodyStyle: {
        padding: '0',
      },
    });
  };
}
