<nz-card nzTitle="Select export columns" [nzExtra]="action">
  <div nz-row [nzGutter]="16">
    <div
      *ngFor="let header of headers"
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="12"
      nzLg="6"
      nzXl="6"
    >
      <label
        nz-checkbox
        [(ngModel)]="header.checked"
        [nzChecked]="header.checked"
        (ngModelChange)="checkAll()"
        style="padding-bottom: 10px"
        >{{ header.header }}</label
      >
    </div>
  </div>
  <div style="text-align: right">
    <button nz-button (click)="onNoClick('no')">Cancel</button>
    <button
      nz-button
      (click)="onNoClick('yes')"
      [disabled]="selectedHeaders.length === 0"
      nzType="primary"
      nzDanger
      style="margin-left: 10px"
    >
      Export
    </button>
  </div>
</nz-card>

<ng-template #action>
  <label
    nz-checkbox
    [(ngModel)]="isAllSelected"
    [nzChecked]="isAllSelected && selectedHeaders.length > 0"
    [nzIndeterminate]="!isAllSelected && selectedHeaders.length > 0"
    (ngModelChange)="toggle()"
    >Select all</label
  >
</ng-template>
