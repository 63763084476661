import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
@Component({
  selector: 'app-selection-export',
  templateUrl: './selection-export.component.html',
})
export class SelectionExportComponent implements OnInit {
  @Input() headers: any;
  selectedHeaders = [];
  isAllSelected = false;
  isIndeterminate = false;

  constructor(private modalRef: NzModalRef) {}

  ngOnInit() {
    this.selectedHeaders = this.headers.filter((header) => header.checked);
    this.checkAll();
  }

  toggle() {
    this.headers.forEach((header) => (header.checked = this.isAllSelected));
    this.selectedHeaders = this.isAllSelected ? this.headers : [];
  }

  checkAll() {
    this.selectedHeaders = this.headers.filter((header) => header.checked);
    if (this.selectedHeaders.length === this.headers.length) {
      this.isAllSelected = true;
      this.isIndeterminate = false;
    } else if (this.selectedHeaders.length > 0) {
      this.isIndeterminate = true;
      this.isAllSelected = false;
    } else {
      this.isIndeterminate = false;
      this.isAllSelected = false;
    }
  }

  onNoClick(action: string): void {
    if (action === 'no') {
      this.headers.forEach((header) => (header.checked = false));
      this.modalRef.destroy();
    } else {
      if (this.selectedHeaders.length > 0) {
        this.modalRef.destroy();
      }
    }
  }
}
