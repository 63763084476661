<!-- Image View -->
<img
  alt="image"
  [hidden]="!isImage"
  [src]="photoURL()"
  style="width: 100%; height: 100%"
/>

<!-- PDF View -->
<iframe
  [hidden]="isImage"
  [src]="photoURL()"
  title="Image Document"
  style="width: 100%; height: 700px"
></iframe>
